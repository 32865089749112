/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  computed,
  ref,
  useRouter,
  useContext,
  useAsync,
} from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import {
  useUiNotification,
  useExternalCheckout,
  useImage,
  useProduct,
} from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import type { UseCartViewInterface } from '~/modules/checkout/composables/useCartView/useCartView';
import type { ConfigurableCartItem, BundleCartItem, CartItemInterface } from '~/modules/GraphQL/types';
import { ProductStockStatus } from '~/modules/GraphQL/types';

import useStock from "~/composables/useStock"
import { useCustomerStore } from "~/modules/customer/stores/customer";

/**
 * Allows loading and manipulating cart view.
 *
 * See the {@link UseCartViewInterface} for a list of methods and values available in this composable.
 */
export function useCartView(): UseCartViewInterface {
  
  const { localePath, app: { i18n, $gtm } } = useContext();
  const { initializeCheckout } = useExternalCheckout();
  const { getMagentoImage, imageSizes } = useImage();
  const router = useRouter();
  const { getProductPath } = useProduct();
  const {
    cart,
    removeItem,
    updateItemQty,
    load: loadCart,
    loading,
    loadingArray
  } = useCart();
  const { isAuthenticated } = useUser();
  const { send: sendNotification, notifications } = useUiNotification();

  const { getMultipleProductStocks } = useStock();
  const customerStore = useCustomerStore();

  const products = computed(() => cartGetters
    .getItems(cart.value)
    .filter(Boolean)
    .map((item) => ({
      ...item,
      product: {
        ...item.product,
        ...[(item as ConfigurableCartItem).configured_variant ?? {}],
        original_sku: item.product.sku,
      },
    }))
    .sort((a, b) => {
      return a.product.sku.localeCompare(b.product.sku);
    })
    );
  const totals = computed(() => cartGetters.getTotals(cart.value));
  const subtotal_excluding_tax = computed(() => cart.value.prices.subtotal_excluding_tax.value)
  const subtotal_including_tax = computed(() => cart.value.prices.subtotal_including_tax.value)
  const discount = computed(() => -cartGetters.getDiscountAmount(cart.value));
  const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
  const getAttributes = (product: ConfigurableCartItem) => product.configurable_options || [];
  const getBundles = (product: BundleCartItem) => product.bundle_options?.map((b) => b.values).flat() || [];
  const isRemoveModalVisible = ref(false);
  const itemToRemove = ref<CartItemInterface>();

  const stockData = ref(null);

  const jsonData = computed(() => {
    return products.value.map((p) => {
      return {sku: p.product.sku, quantity: p.quantity}
    })
  });

  const isBackorderConfirm = ref(false)
  const updatingBackorder = ref(false)

  useAsync(async () => {
    if (!cart.value || cart.value && cart.value.id === '') {
      await loadCart();
    }
    const skus = products.value.map((item) => item.product.sku);
    stockData.value = await getMultipleProductStocks(skus);
  })

  const sendDataLayer = async () => {
    await $gtm.push({ ecommerce: null })
    await $gtm.push({
      event: "begin_checkout",
      ecommerce: {
        items: products.value.map((p, i) => {
          return {
            item_name: p.product.name,
            item_id: p.product.sku,
            index: 1 + i,
            price: p.product.price_range.maximum_price.final_price.value,
            currency: p.product.price_range.maximum_price.final_price.currency,
          }
        })
      }
    })
  }

  const redirectToCheckout = async () => {
    await sendDataLayer()
    const redirectUrl = initializeCheckout({ baseUrl: '/checkout/user-account' });
    await router.push(localePath(redirectUrl));
  }

  const mappedData = ref([])

  const checkForBackorders = async () => {
    mappedData.value = products.value.map((item) => {
      const match = stockData.value.value.find(s => s.itemNo === item.product.sku)

      if(match && item.quantity > match.availableInventory) {
        return {sku: match.itemNo, avail_q: match.availableInventory, requested_q: item.quantity, product: item}
      }
    }).filter(item => item)

    if(mappedData.value.length > 0) {
      isBackorderConfirm.value = true
    } else {
      redirectToCheckout()
    }
  }

  const skusThatWillBeRemoved = computed(() => {
    return mappedData.value.map(i => i.sku)
  })

  const continueWithOrder = async () => {
    redirectToCheckout()
  }

  const filterItemsThatAreNotInStock = async () => {
    updatingBackorder.value = true
    for (const i of mappedData.value) {
      if (i.avail_q === 0) {
        const product = i.product;
        await removeItem({ product }); // Wait for the item to be removed
      } else if (i.avail_q < i.requested_q) {
        await updateItemQty({ product: i.product, quantity: i.avail_q }); // Wait for the item quantity to be updated
      }
    }

    isBackorderConfirm.value = false;
    updatingBackorder.value = false
  
    // Redirect to checkout after everything is done
    redirectToCheckout();
  };

  const goToCheckout = async () => {
    const backorder = customerStore?.userAdv?.backorder_allowed === '1' ? true : false
    if(backorder) {
      redirectToCheckout()
    } else {
      await checkForBackorders()
    }
  };

  const showRemoveItemModal = ({ product }: { product: CartItemInterface }) => {
    if (notifications.value.length > 0) {
      notifications.value[0].dismiss();
    }

    isRemoveModalVisible.value = true;
    itemToRemove.value = product;
  };

  const removeItemAndSendNotification = async (product: CartItemInterface) => {
    isRemoveModalVisible.value = false;
    await removeItem({ product });

    // sendNotification({
    //   id: Symbol('product_removed'),
    //   message: i18n.t('{0} has been successfully removed from your cart', {
    //     0: cartGetters.getItemName(
    //       product,
    //     ),
    //   }) as string,
    //   type: 'success',
    //   icon: 'check',
    //   persist: true,
    //   title: 'Product removed',
    // });
  };

  const delayedUpdateItemQty = debounce(
    (params) => updateItemQty(params),
    1000,
  );

  const isInStock = (product: CartItemInterface) => cartGetters.getStockStatus(product) === ProductStockStatus.InStock;

  return {
    showRemoveItemModal,
    removeItemAndSendNotification,
    delayedUpdateItemQty,
    goToCheckout,
    getAttributes,
    getBundles,
    isInStock,
    getMagentoImage,
    getProductPath,
    loading,
    isAuthenticated,
    products,
    isRemoveModalVisible,
    itemToRemove,
    totals,
    totalItems,
    imageSizes,
    discount,
    cartGetters,
    subtotal_excluding_tax,
    subtotal_including_tax,
    loadCart,
    isBackorderConfirm,
    updatingBackorder,
    continueWithOrder,
    filterItemsThatAreNotInStock,
    jsonData,
    skusThatWillBeRemoved,
    stockData,
    loadingArray
  };
}

export default useCartView;
export * from './useCartView';
